.canvas-container {
  display: inline-block;
  position: relative;
  width : 100%;

  /*uncomment to change size of canvas block*/
  /*width: 75%;*/
  /*margin-left: 12.5%;*/

  border : 5px #eaeef3 solid;
  background-color: white;
  border-radius: 7px;
}

.canvas-dummy {
  margin-top: 100%;
  /* 1:1 aspect ratio */
}

.canvas-element {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 7px;
  width : 100%;
  padding-bottom: 100%; /*1:1 aspect ratio*/
  height: 0px !important;
  /*border-bottom-right-radius: 0px;*/
  /*border-bottom-left-radius: 0px;*/
}

canvas {
  outline: none;
}

.normal-map-container {
  display: inline-block;
  position: relative;
  width : 100%;
  height: 100%;

  border : 5px #eaeef3 solid;
  background-color: #eaeef3;
  border-radius: 7px;
}

.normal-map-element {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 7px;
  width : 100%;
  padding-bottom: 75%; /*4:3 aspect ratio*/
  height: 0px !important;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.card-scroller {
  /*overflow-x: scroll;*/
  display: table;
  width: 100%;
}

.rawscancard {
  margin-right: 10px;
  margin-top: 10px;
  display: -webkit-inline-flex;
}

.rawimagecard {
  margin-right: 10px;
  margin-top: 10px;
  display: -webkit-inline-flex;
}

.mediacard {
  margin-right: 10px;
  margin-top: 10px;
  display: -webkit-inline-flex;
}