.table thead.archive-full-head {
  font-size: 11px;
}

.table tbody.archive-full-body {
  font-size: 12px;
}

.graph-container {
  width: 100%;
  height: 100%;
}